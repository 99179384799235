import * as deepmerge from "deepmerge";
import Inputmask from "inputmask";

window.showEdit = showEdit;
window.confirmCustom = confirmCustom;
window.confirmAndHandle = confirmAndHandle;
window.showForm = showForm;
window.modalSave = modalSave;
window.hideModal = hideModal;

function showModal() {
    var $modal = $("#sharedModal");
    $modal.modal("show").find(".modal-dialog").removeClass("modal-lg").find(".modal-content").empty();
    preloader($modal, true);
}

function hideModal() {
    var $modal = $("#sharedModal");
    $modal.modal("hide");
}

function contentModal(content) {
    var lg = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var $modal = $("#sharedModal");

    if (lg) {
        $modal.find(".modal-dialog").addClass("modal-lg");
    }

    $modal.find(".modal-content").html(content);
    return $modal;
}

function preloader($element) {
    var show = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var $preloader = $element.find(".preloader-box");

    if (show) {
        $preloader.addClass("show");
        return;
    }

    $preloader.removeClass("show");
}

function showForm(element, params = {}) {
    let $element = $(element),
        $modal = $("#sharedModal"),
        $content = $modal.find(".modal-content"),
        $preloader = $modal.find(".preloader-box"),
        data = {...$element.data()};

    delete data["bs.tooltip"];
    delete data["tooltip"];

    let {
        reportDateFieldName = "ReportDate",
        wellIdFieldName = "WellId",
        lockForm = false,
        lg = false,
        dataWell = false
    } = params;

    if (lg) {
        $modal.find(".modal-dialog").addClass("modal-lg");
    }
    
    $content.html("");
    $preloader.addClass("show");
    $modal.draggable({
        handle: '.modal-header, .modal-footer'
    });
    $modal.modal("show");
    
    if (dataWell) {
        data[wellIdFieldName] = document.querySelector('input#Id').value;
        data[reportDateFieldName] = document.querySelector('input#reportDateDatepicker').value;
    }
    
    $.ajax({
        url: data.url,
        data: JSON.stringify(data),
        type: "POST",
        contentType: 'application/json; charset=utf-8',
    }).done((result) => {
        $content.html(result);
        //$content.find('input').each(x => Inputmask().mask(x));
        if (lockForm) {
            lockForm($content);
        }
        $preloader.removeClass("show");
    });
}

function showEdit(aPromise, model, params = {}) {
    showModal();

    aPromise(model, params)
        .then(function (result) {
            let $modal = contentModal(result, true);
            let editModel = $modal.get(0);

            editModel.dataset.index = model.index;

            //
            // editModel.querySelector('#Type').value = model.type;
            //
            // editModel.querySelector('#MudSystem').value = model.mudSystem;
            //
            // editModel.querySelector('#addMud_time').value = model.time;
            //
            // $(".inputmask input").inputmask();

            $modal.find(".btn-add").hide();

            // if (LockForm)
            //     lockForm($modal);

            preloader($modal);

            if (!$modal[0])
                return;
            const editTitle = $modal[0].querySelector('.modal-title title-edit');
            if (editTitle) {
                editTitle.style.display = 'block';
                if (LockForm) {
                    editTitle.style.display = 'none';
                }
            }

            const addTitle = $modal[0].querySelector('.modal-title title-add');
            if (addTitle) {
                addTitle.style.display = 'none';
            }
        });
}

function showEditAdminServiceItem(model, params = {}) {
    showModal();

    let {
        LockForm = false
    } = params;

    var data = {
        // ParentId: $("#Id").val(),
        // Date: reportDateDatepicker.value,
        // Index: model.index
    };

    $.get('/AdminServices/Form', data, result => {

        let $modal = contentModal(result, true);
        let editModel = $modal.get(0);

        editModel.dataset.index = model.index;

        //
        // editModel.querySelector('#Type').value = model.type;
        //
        // editModel.querySelector('#MudSystem').value = model.mudSystem;
        //
        // editModel.querySelector('#addMud_time').value = model.time;
        //
        // $(".inputmask input").inputmask();

        $modal.find(".btn-add").hide();

        if (LockForm)
            lockForm($modal);

        preloader($modal);

        if (!$modal[0])
            return;
        const editTitle = $modal[0].querySelector('.modal-title title-edit');
        if (editTitle) {
            editTitle.style.display = 'block';
            if (LockForm) {
                editTitle.style.display = 'none';
            }
        }

        const addTitle = $modal[0].querySelector('.modal-title title-add');
        if (addTitle) {
            addTitle.style.display = 'none';
        }


    });
}


function confirmCustom(element, resultTab = false, reportType = false, reportDateFieldName = "Date", modalId = 'confirmModal', eventHandler = false) {
    let $modal = $(`#${modalId}`),
        $element = $(element),
        $btnConfirm = $modal.find(".btn-confirm"),
        $tr = $element.closest("tr"),
        date = $element.data("date");

    if (reportType !== false) {
        $(".report-text.report-type-" + reportType).removeClass("d-none");
        $(".report-text:not(.report-type-" + reportType + ")").addClass("d-none");
    }

    $btnConfirm.prop("disabled", false);
    $modal.modal("show");

    $btnConfirm.one("click", function () {
        $btnConfirm.prop("disabled", true);

        let data = $element.data();
        data["Id"] = $element.data("id");

        delete data['bs.tooltip'];

        if (eventHandler !== false) {
            let res = eventHandler();
            //if (res) {
            //    $modal.modal("hide");
            //} else {
            //    $btnConfirm.prop("disabled", false);
            //}
            $modal.modal("hide");
        } else {
            $.ajax({
                url: $element.data("url"),
                data: JSON.stringify(data),
                type: "POST",
                contentType: 'application/json; charset=utf-8',
            }).done(function(result) {
                if (resultTab) {
                    $tr.closest(".tab-pane").html(result);
                } else {
                    $tr.remove();
                }

                $modal.modal("hide");
            });
        }
    });
}


function confirmAndHandle(element, message, eventHandler = null) {
    let modalId = 'confirmModal';
    let $modal = $(`#${modalId}`),
        $element = $(element),
        $btnConfirm = $modal.find(".btn-confirm"),
        $tr = $element.closest("tr"),
        date = $element.data("date");

    var result = DevExpress.ui.dialog.confirm(message);
    result.done(async function (dialogResult) {
        if (!dialogResult) {
            return;
        }

        let data = $element.data();
        data["Id"] = $element.data("id");

        delete data['bs.tooltip'];

        $.ajax({
            url: $element.data("url"),
            data: JSON.stringify(data),
            type: "POST",
            contentType: 'application/json; charset=utf-8',
        }).done(function(result) {
            if (eventHandler !== null) {
                eventHandler();
            } else {
                if (resultTab) {
                    $tr.closest(".tab-pane").html(result);
                } else {
                    $tr.remove();
                }
            }
            $modal.modal("hide");
        });
    });
}

function confirmAndHandleOld(element, eventHandler = null) {
    let modalId = 'confirmModal';
    let $modal = $(`#${modalId}`),
        $element = $(element),
        $btnConfirm = $modal.find(".btn-confirm"),
        $tr = $element.closest("tr"),
        date = $element.data("date");


    $btnConfirm.prop("disabled", false);
    $modal.modal("show");

    $btnConfirm.one("click", function () {
        $btnConfirm.prop("disabled", true);

        let data = $element.data();
        data["Id"] = $element.data("id");

        delete data['bs.tooltip'];

        $.ajax({
            url: $element.data("url"),
            data: JSON.stringify(data),
            type: "POST",
            contentType: 'application/json; charset=utf-8',
        }).done(function(result) {
            if (eventHandler !== null) {
                eventHandler();
            } else {
                if (resultTab) {
                    $tr.closest(".tab-pane").html(result);
                } else {
                    $tr.remove();
                }
            }
            $modal.modal("hide");
        });
    });
}
$(document).ready(function () {
    $('.modal .error-box button').click(function () {
        $('.modal.show').find(".error-box").removeClass("show");
        $('.modal.show').modal('hide');
    });
});


function toggleNode(element) {
    $(element).closest(".node").toggleClass("open");
}

function modalSave(element) {
    let modal = element.closest('.modal'),
        modalBody = modal.querySelector('.modal-body'),
        data = { ...element.dataset },
        model = getDataForm(modalBody);

    delete data['bs.tooltip'];
    delete data['tooltip'];

    $.ajax({
        url: data.url,
        type: 'POST',
        data: JSON.stringify(model),
        contentType: 'application/json; charset=utf-8',
        beforeSend: () => {
            lockForm();
        },
        complete: (jqXHR, textStatus) => {
            unlockForm();
        }
    }).done(result => {
        $(`.${data.table}`).replaceWith(result);
        $(modal).modal('hide');
    });

    function lockForm() {
        modal.querySelectorAll('input, button, select').forEach(x => x.disabled = true);

        const spinner = modal.querySelector('.btn-confirm .spinner-border');
        if (spinner) {
            spinner.style.display = 'block';
        }
    }

    function unlockForm() {
        modal.querySelectorAll('input, button, select').forEach(x => x.disabled = false);

        const spinner = modal.querySelector('.btn-confirm .spinner-border');
        if (spinner) {
            spinner.style.display = 'none';
        }
    }
}

/**
 * Универсальная функция получения данных формы
 *
 * @param {HTMLElement} element (HTML-элемент) –
 **/
function getDataForm(selector, addNameRow = false) {
    let fields = $(selector).find("[name]:not(.row)");
    var fieldsFirstLvl = [];

    fieldsFirstLvl = fields.filter(function (index, item) {
        let $item = $(item);
        let $row = $item.closest('.row');
        
        if ($item.closest('.list-row').length > 0) {
            return false;
        }
        
        if (addNameRow) {
            let rowName = $row.attr("name");
            let itemName = $item.attr("name").replace(rowName + ".", "");

            if ($row.length > 0 && rowName) {
                fields[index].name = rowName + "." + itemName;
            }
        }
        
        return !item.classList.contains('list');
    });

    let complexFields = getComplexObject(fieldsFirstLvl, "[name]");

    fields.each((index, field) => {
        let $this = $(field);

        if (field.classList.contains("list")) {
            fields = fields.filter((index, item) => item != field);
            let listArray = [];

            let listRows = $this.find(".list-row");

            listRows.each((i, listRow) => {
                var $listRow = $(listRow).find("[name]");

                listArray.push(getComplexObject($listRow, "[name]"))
            });
            complexFields[$this.attr('name')] = listArray;
            return;
        }
    });

    return complexFields;
}

/**
 * Получение объекта на основе его описания в представлении.
 * 
 * Описание в представлении - это заполненный атрибут data-field-name, описывающий расположение поля в объекте. Например data-field-name="Depth.Uom" говорит о том, что есть объект Depth, а внутри него поле Uom
 * Таким образом имея два элемента управления <input type="number" data-field-name="Depth.Value" value="10"> и <input type="text" data-field-name="Depth.Uom" value="Км"> данная функция создаст объект {"Depth":{"Value":"10","Uom":"Км"}}
 *
 * @param {jQuery} $element jquery-элемент внутри которого будет производиться поиск элементов
 * @param {string} selector селектор по которому будут выбираться поля в форме (name или data-field-name)
**/
function getComplexObject(fields, selector = "[name]") {
    //fields = $($element).find(selector)

    let elementsArray = [];
    
    fields.each((index, field) => {
        let elementNames = [];
        
        if (selector === "[name]") elementNames = field.attributes['name'].value.split(".");//field.name.split(".");
        if (selector === "[data-field-name]") elementNames = field.dataset.fieldName.split(".");
        
        let lastElementName = elementNames[elementNames.length - 1];
        let complexType = {};
        elementNames.reduce((obj, el) => {
            if (el === lastElementName) {
                if ((field.dataset && field.dataset.type) === "number" || field.type === "number") {
                    let numberValue = field.value && field.value.replace(",", ".");
                    if(numberValue === ""){
                        return obj[el] = null;
                    }
                    return obj[el] = Number(numberValue);
                }
                /*if (field.classList.contains("dx-widget")) {
                    // Временное решение:
                    // Если переданный элементу управления является элементом от Devextreme, то получить значение следует из ближайшего input'а так функция dxDateBox не работает
                    if (field.classList.contains("dx-numberbox")) {
                        
                        return obj[el] = field.querySelector("input").value.replace(",", ".");//$(field).dxDateBox("instance").option('value');
                    }
                    return obj[el] = field.querySelector("input").value;//$(field).dxDateBox("instance").option('value');
                }*/
                /*if (field.parentElement.classList.contains("dx-widget")) {
                    // Временное решение:
                    // Если переданный элементу управления является элементом от Devextreme, то получить значение следует из ближайшего input'а так функция dxDateBox не работает
                    if (field.parentElement.classList.contains("dx-numberbox")) {
                        return obj[el] = field.value.replace(",", ".");//$(field).dxDateBox("instance").option('value');
                    }
                }*/
                let closestDxWidget = field.closest('.dx-widget');
                if (closestDxWidget) {
                    let dxElementValue = getDxElementValue(closestDxWidget);
                    return obj[el] = dxElementValue;//dxElement.option('value');
                }
                if (field.tagName == 'SELECT') {
                    let selectedOption = field.options[field.options.selectedIndex];
                    if (selectedOption) {
                        return obj[el] = selectedOption.value === '' ? selectedOption.text : selectedOption.value;
                    }
                }
                return obj[el] = field.value;
            }
            else {
                return obj[el] = {};
            }
        }, complexType);
        elementsArray = deepmerge.all([elementsArray, complexType]);
    });

    return elementsArray;
}