window.logout = logout;
window.gotolink = gotolink;
window.confirmCreateWell = confirmCreateWell;
window.toggleNode = toggleNode;
window.keyEnter = keyEnter;
window.filterTree = filterTree;
window.showForm = showForm;
window.jqueryUIDraggable = jqueryUIDraggable;

function toggleNode(element) {
    $(element).closest(".node").toggleClass("open");
}

function logout() {
    $.ajax({
        url: window.pb + "/Account/Logout",
    }).done(result => {
        if (result.close) {
            window.close(); // закрываем вкладку
        }
        else {
            location.href = window.pb + "/"; // возвращаемся на страницу авторизации
        }
    });
}


function getCookie(name) {
    var r = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
    if (r) return r[2];
    else return "";
}

function gotolink(target){
    $.ajax({
        url: window.pb + target,
        beforeSend: function(request) {
            request.setRequestHeader("Authorization",  getCookie('access_token'));
        },
    }).done(result => {
        var newWindow = window.open();

        //write the data to the document of the newWindow
        newWindow.document.write(result);
    });
}

function $_GET(key) {
    let p = window.location.search;
    p = p.match(new RegExp(key + '=([^&=]+)'));
    return p ? p[1] : false;
}

function confirmCreateWell() {
    let add = $_GET("addWell");

    if (add.toLowerCase() === "false") {
        $("#wellEditForm").submit();
        return false;
    }

    let $modal = $("#confirmModal"),
        $btnConfirm = $modal.find(".btn-confirm"),
        $text = $modal.find(".modal-text"),
        name = $("#Name").val();

    $btnConfirm.prop("disabled", false);
    $text.html(`Вы создаёте новую скважину с именем: "${name}". <br><br><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Внимание, переименовать скважину будет невозможно!`);

    $modal.modal("show");

    $modal.on('hide.bs.modal', function () {
        $btnConfirm.off();
    });

    $btnConfirm.one("click", function () {
        $btnConfirm.prop("disabled", true);
        $("#wellEditForm").submit();
        $modal.modal("hide");
    });
}

function keyEnter(event) {
    if(event.keyCode === 13){
        event.preventDefault();
        filterTree();
    }
}

function filterTree(trigger = "filter") {
    const $node = $("#tree .node");
    const $inputFilter = $('#input-object-filter');
    const value = $inputFilter.val();
    
    if (trigger === "collapse") {
        $node.removeClass("open").removeClass("d-none");
        return;
    }

    if (trigger === "expand") {
        $node.addClass("open").removeClass("d-none");
        return;
    }

    if (value === '') {
        $node.removeClass("d-none");
        return;
    }

    let founded = $node.filter((index, element) => {
        if (element.dataset.id === "#") {
            return true;
        }
        return element.querySelector("div > div").textContent.toLowerCase().includes(value.toString().toLowerCase());
    });

    let fullList = [];
    founded.each((index, element) => {
        GetParentObject($(element).data("id"), fullList);
    });

    $node.each((index, element) => {
        let id = $(element).data("id");
        let text = element.querySelector("span.text");
        if (fullList.indexOf(String(id)) > -1) {
            element.classList.add("open");
            element.classList.remove("d-none");
            if (text) text.innerHTML = highlight(value, text);
        } else {
            element.classList.add("d-none");
            element.classList.remove("open");
            if (text) text.innerHTML = highlight(value, text, true);
        }
    });
}

function highlight(str_text, text, clear = false) {
    if (clear) {
        return text.textContent;
    }
    
    let replaceRegExp = new RegExp(str_text, 'gi');
    let content = text.textContent;
    let rep = content.match(replaceRegExp);
    
    if (rep === null) {
        return content;
    }

    return content.replace(replaceRegExp, '<mark class="highlight">' + rep[0] + '</mark>' );
}

/**
 * @return {undefined}
 */
function GetParentObject(id, fullList) {
    if (id === undefined) {
        return; 
    }
    const nodeObject = $(`#tree .node[data-id='${id}']`),
        parentId = nodeObject.data('parent-id');
    fullList.push(id.toString());

    if (parentId === '#' || parentId === '') {
        return;
    }

    return GetParentObject(parentId, fullList);
}

function showForm(element, param = {}) {
    let $modal = $("#sharedModal");

    preloader($modal);
    $modal.modal("show");

    let {
        Url,
    } = param;

    $.ajax({
        url: window.pb + Url,
        type: "POST",
    }).then(result => {
        $modal.find(".modal-content").html(result);
        preloader($modal);
    });
}

function preloader($selector, action = "") {
    let $preloader = $selector.find(".preloader-box");

    switch (action) {
        case "show":
            $preloader.addClass("show");
            break;
        case "hide":
            $preloader.removeClass("show");
            break;
        default:
            $preloader.toggleClass("show");
    }
}

$(document).ajaxError((event, request, settings) => {
    console.log({ event, request, settings });
    switch (request.status) {
        case 401:
            window.location.replace('/Account/Login');
            break;
        default:

            if (request.responseText == null) {
                break;
            }   
            let $modal = $('.modal.show'),
                $errorBox = $modal.find('.errors-box');
            
            if ($errorBox.length !== 0) {
                $errorBox.text(request.responseText).removeClass("d-none");
                $modal.find(".modal-body").scrollTop(0);
            }
            else {
                alert(request.responseText);
                //DevExpress.ui.notify({ message: request.responseText, width: 250, shading: false, type: "error", displayTime: 5000 });
            }

            break;
    }
});

$(document).on('shown.bs.modal', '.modal', function (e) {
    jqueryUIDraggable(this);
    
    $(this).find(`[data-dismiss="modal"]`).one("click", () => $(this).modal('hide'));
});

function jqueryUIDraggable(element) {
    $(element).draggable({
        handle: '.modal-header, .modal-footer'
    }).find('.errors-box').addClass("d-none");
}